as-split:not(.as-disabled) {
  .as-split-gutter {
    padding: 2px !important;
    flex-basis: 5px !important;
    // background-color: #e6e6e6 !important;
    margin-bottom: 11px;

    &>.as-split-gutter-icon {
      pointer-events: all;
      transition: all 0.3s;
      position: absolute;
      background-color: #fff;
      border-radius: 25px !important;
      border: 1px solid #e9e9e9 !important;
      width: 28px !important;
      height: 28px !important;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.as-horizontal>.as-split-gutter .as-split-gutter-icon {
    background-image: url("/assets/img/icon/arrowLR.svg") !important;
    background-size: 24px 24px;
  }

  &.as-vertical .as-split-gutter-icon {
    background-image: url("/assets/img/icon/arrowTB.svg") !important;
    background-size: 24px 24px;
  }
}

.as-split-area {
  overflow-x: auto !important;
}
