@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("assets/css/tailwind.output.css");

.menu-link {
  @apply flex items-center py-2 text-sm shadow-sm text-gray-600 hover:text-purple-700 hover:bg-purple-200 rounded;
}

.menu-current {
  @apply text-black;
}

.radio-button {
  @apply text-center self-center py-1 px-1 cursor-pointer rounded-lg;
}

.radio-button:hover {
  @apply opacity-75;
}

.current-checked {
  @apply text-white bg-purple-300;
}

.min-w-500 {
  min-width: 500px;
}

.min-w-300 {
  min-width: 300px;
}

.min-w-80pa {
  min-width: 80%;
}

.min-w-60pa {
  min-width: 60%;
}

.btn-white {
  @apply flex items-center ml-8 px-2 py-2 text-sm font-medium leading-3 text-black transition-colors duration-150 bg-white border rounded-lg hover:bg-gray-200 focus:outline-none focus:shadow-outline-gray;
}

.btn-primary {
  @apply flex items-center ml-8 px-2 py-2 text-sm font-medium leading-3 text-white transition-colors duration-150 bg-purple-500 border border-transparent rounded-full active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple;
}

.btn-cancel {
  @apply flex items-center ml-8 px-2 py-2 text-sm font-medium leading-3 text-gray-700 transition-colors duration-150 border border-purple-600 rounded-full focus:outline-none focus:shadow-outline-purple;
}

.th-head {
  @apply px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 bg-gray-100 border-b border-gray-200;
}

.td {
  @apply px-6 py-4 border-b border-gray-200 flex items-center;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.td-title {
  @apply text-sm font-medium leading-5 text-gray-900 break-words;
}

.td-label {
  @apply text-sm leading-5 text-gray-900;
}

.td-active {
  @apply inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full;
}

.td-btn {
  @apply px-2 text-indigo-600 hover:text-indigo-900;
}

.td-btn-red {
  @apply px-2 text-red-600 hover:text-red-900;
}

.editor-func-btn {
  @apply z-10 bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-2 py-1 mr-1 text-sm font-medium;
}

.editor-func-btn.ping {
  @apply bg-gray-50;
}

// ::-webkit-scrollbar {
//   width: 7px;
//   height: 7px;
// }

// ::-webkit-scrollbar-track{
//   background: #2D3748;
// }

// ::-webkit-scrollbar-thumb{
//   background: #CBD5E0;
// }

// ::-webkit-scrollbar-thumb:hover{
//   background: #718096;
// }

.form-input-invalid {
  @apply border-red-600 focus:border-red-400;
}
