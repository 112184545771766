.selection_border {
    fill: none;
    stroke: black;
    stroke-width:1;
  }

  .selection_handle_shear {
    fill: white;
    stroke: black;
    stroke-width:1;
  }

  .selection_handle_rot {
    fill: white;
    stroke: black;
    stroke-width:1;
  }

  .selection_handle_ls, .selection_handle_rs{
    cursor: crosshair;
  }

  .selection_handle_lt{
      cursor: nw-resize;
  }
  .selection_handle_rt{
      cursor: ne-resize;
  }
  .selection_handle_rb{
      cursor: se-resize;
  }
  .selection_handle_lb{
      cursor: sw-resize;
  }
  .selection_handle_t{
      cursor: n-resize;
  }
  .selection_handle_r{
      cursor: e-resize;
  }
  .selection_handle_b{
      cursor: s-resize;
  }
  .selection_handle_l{
      cursor: w-resize;
  }

  .selection_handle_rot{
      stroke-width:1;
      stroke:black;
      fill: #F9FFED;
  }

  .selection_handle_point{
      cursor: move;
  }

  .svg_select_boundingRect{
      stroke-width:1;
      fill:gray;
      stroke-dasharray:10 10;
      stroke:black;
      stroke-opacity:0.8;
      fill-opacity:0.1;
      pointer-events:none; /* This ons is needed if you want to deselect or drag the shape*/
  }
